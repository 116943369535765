const RolePermissions = {
  admin: [
    // Admin Module
    "home.admin.page",
    "home.admin.add",
    "home.admin.update",
    "home.admin.delete",

    // Student Module
    "home.student.page",
    "home.student.add",

    // Blueprint Module
    "home.blueprint.page",
    "home.blueprint.add",
    "home.blueprint.update",
    "home.blueprint.delete",

    // Teacher Module
    "home.teacher.page",
    "home.teacher.add",

    // Enrollment
    "home.enrollment.add",
  ],
  teacher: ["home.student.page"],
  sub_admin: [
    // Teacher Module
    "home.teacher.page",
    "home.teacher.add",

    // Student Module
    "home.student.page",
    "home.student.add",

    // Blueprint Module
    "home.blueprint.page",

    // Enrollment
    "home.enrollment.add",
  ],
};

module.exports = RolePermissions;
